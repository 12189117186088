<template>
    <div class="content">
        <div class="container">
            <div>
                <div class="shop-name">店铺:{{shop_name}}</div>
                <div class="content1">
                    <div class="left-img">
                        <img :src="recommend_list&&recommend_list[0].imgurl" />
                    </div>
                    <div class="r-con">
                        <div class="top-con">
                            <div class="left-con">
                                <div class="l-con">
                                    <img :src="shop_logo" />
                                </div>
                                <div class="r-con1">
                                    <div class="shop-name">{{shop_name}}</div>
                                    <p><span style="color:red;">{{quantity_on_sale}}</span>件在售商品|已售<span style="color:red;">{{quantity_sold}}</span>件</p>
                                </div>
                            </div>

                            <div class="right-con">
                                <div class="collect-btn">
                                    <div class="btn" @click="toCollect">
                                        <img src="@/assets/image/mch_sc.png" v-if="!collection_status=='1'"/>
                                        {{collection_status=='1'?'已收藏':'收藏'}}
                                    </div>
                                    <p class="num">已有{{collection_num}}人收藏店铺</p>
                                </div>
                            </div>
                        </div>

                        <div class="info">{{shop_information}}</div>
                        <div class="bottom-con">
                            <img src="@/assets/image/address_icon.png" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="recommend-con">
                <h3>店铺推荐</h3>
                <div class="list-con">
                    <div class="item" v-for="item in recommend_list" :key="item.id" @click="todetail(item.id)">
                        <img :src="item.imgurl" class="good-img"/>
                        <div class="title">{{item.product_title}}</div>
                        <div class="bottom">
                            <span>¥{{item.price}}</span>
                        </div>
                    </div>
                </div>
            </div>


            <div style="margin-top:20px;">
                <div class="tag-con">
                    <div class="title">全部商品</div>
                </div>
            </div>
            <div class="class-list" v-if="class_list.length>0">
                <div class="title" style="width:85px;">分类:</div>
                <template v-if="!checked[1]">
                    <div class="class-con" v-for="item in class_list" :key="item.cid" @click="toclassify(item.cid,item.pname)">
                        <div class="item">
                            {{item.pname}}
                        </div>
                    </div>
                </template>
                <template v-else>
                    <el-checkbox-group v-model="checkList" style="width:100%;display:flex;align-items:center;padding: 5px 0px;">
                        <el-checkbox :label="item.pname" v-for="item in class_list" :key="item.cid" style="width:10%;" @change="brandAddall1(item.cid)"></el-checkbox>
                    </el-checkbox-group>
                </template>
                <el-button class="el-icon-plus" size="mini" @click="clickChecked(1)">
                    {{checked[1]?'多选':'单选'}}
                </el-button>
            </div>
            <div class="class-list" style="border-bottom:1px solid rgb(238, 238, 238)">
                <div class="title" style="width:85px;">价格:</div>
                <div class="class-con" v-for="(item,index) in pricelist" :key="index" @click="addprice(item)">
                    <div class="item">
                         ￥{{item}}
                    </div>
                </div>
                <div class="input-con">
                    ￥
                    <el-input v-model="low"></el-input>
                    -
                    <el-input v-model="high"></el-input>
                    <el-button class="surebtn" size="mini" @click="rangeselect">确定</el-button>
                </div>
            </div>

            <div class="default">
                <div class="default-con">
                    <div class="title1" :style="{'color': (order==1 ? '#020202':'#999999')}" @click="changeorder(1)">默认</div>
                    <div class="title1 price" @click="changeorder(2)" :style="{'color': (order==2 ? '#020202':'#999999')}">
                        <div>价格</div>
                        <div class="arrow-con">
                            <i class="el-icon-caret-top" :style="{'color':(order_arrow&&order==2?'#020202':'#999999')}" @click="changeArrow(true)"></i>
                            <i class="el-icon-caret-bottom" style="margin-top:-6px;" :style="{'color':(!order_arrow&&order==2?'#020202':'#999999')}" @click="changeArrow(false)"></i>
                        </div>
                    </div>
                    <div class="title1" @click="changeorder(3)" :style="{'color': (order==3 ? '#020202':'#999999')}">销量</div>
                </div>
            </div>

            <div class="pageation">
                <div class="page-con" v-for="item in list" :key="item.id">
                    <img :src="item.imgurl"/>
                    <div style="padding:20px 0">
                        <div class="comidty-title">{{item.product_title}}</div>
                        <div class="price-title">￥{{item.price}}</div>
                    </div>
                </div>
            </div>
            <div style="width:100%;display:flex;" v-if="list.length>0">
                <div style="margin:30px auto 0;">
                    <el-pagination :page-size="16" :current-page="page" @current-change="currentChange" background layout="prev, pager, next" :total="total">
				</el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {onMounted, reactive,ref, toRefs} from 'vue'
import {useRoute,useRouter} from 'vue-router'
import {getData} from '@/utils/req'
export default {
    setup(){
        const route = useRoute()
        const router = useRouter()
        let pc_user = ref('')
        let state = reactive({
            shop_id: '',
            cid: '',
            keyword: '',
            class_id: '',
            amount: '',
            sort_type: '',
            sort: '',
            page: 1
        })

        const checked = ref([
           false,false
        ])
        const pricelist= ref([
            '0-449','500-1000','1000-1500','1500以上'
        ])
        let temp = reactive({
            class_list:'',
            collection_num:'',
            collection_status:'',
            is_open:'',
            list:'',
            mch_address:'',
            quantity_on_sale:'',
            quantity_sold:'',
            recommend_list:'',
            shop_information:'',
            shop_logo:'',
            shop_name:'',
            total:''
        })
        onMounted(()=>{
            state.shop_id = route.query.mch
            pc_user.value = JSON.parse(localStorage.getItem('pc_user'))
            axios()
        })
        const todetail = (id)=>{
            const { href } = router.resolve({
                path: '/homedetail',
                query: {
                    good_id:id
                }
            });
            window.open(href, "_blank");
        }
        const currentChange = (e) =>{
            state.page = e
            axios()
        }
        const axios = () =>{
            let data ={
                module: 'app_pc',
                action: 'mch',
                m: 'store_homepage',
                access_id:pc_user.value.access_id
            }
            data = Object.assign(data,state)
            getData(data).then(res=>{
                temp = Object.assign(temp,res.data)
            })
        }
        return {
            checked,
            pricelist,
            ...toRefs(temp),
            todetail,
            currentChange
        }
    }
}
</script>
<style scoped lang="scss">
    .content{
        width: 100%;
        padding-top: 20px;
        border-top: 1px solid rgb(230, 230, 230);
        .container{
            width: 1200px;
            margin: 0 auto 0;

             .recommend-con{
                width: 100%;
                margin-top: 70px;
                margin-bottom: 70px;
                h3{
                    color: #020202;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 20px;
                }
                .list-con{
                    display: flex;
                    align-items: center;
                    margin-top: 15px;
                    .item{
                        text-align: center;
                        width: 288px;
                        border:1px solid #eee;
                        cursor: pointer;
                        .good-img{
                            width: 100%;
                        }
                        .title{
                            font-weight: bold;
                            color: #020202;
                            font-size: 14px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin-top: 30px;
                            margin-bottom: 10px;
                        }
                        .bottom{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-bottom: 30px;
                            span{
                                color: #D4282D;
                                font-size: 14px;
                                vertical-align: middle;
                            }
                            .cart{
                                width: 24px;
                                height: 24px;
                                background: #EEEEEE;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-left: 5px;
                                .cart-img{
                                    width: 16px;
                                    
                                }
                            }
                        }
                    }
                    .item:not(:last-child){
                        margin-right: auto;
                    }
                    .item:hover{
                        background: #eee;
                    }
                }
            }
            .shop-name{
                font-weight: 400;
                color: #606266;
                cursor: text;
            }
            .content1{
                display: flex;
                align-items: center;
                .left-img{
                    height: 242px;
                    img{
                        height: 242px;
                    }
                    
                }
                .r-con{
                    flex: 1;
                    height: 242px;
                    background: #f6f6f6;
                    padding:26px;
                    .top-con{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .left-con{
                            display: flex;
                            .l-con{
                                width: 68px;
                                height: 68px;
                                img{
                                    width: 100%;
                                }
                            }
                            .r-con1{
                                margin-left: 15px;
                                .shop-name{
                                    color: #020202;
                                    font-weight: bold;
                                    font-size: 18px;
                                    line-height: 18px;
                                    margin-bottom: 15px;
                                    margin-top: 7px;
                                }
                                p{
                                    font-size: 14px;
                                    line-height: 14px;
                                }
                            }
                        }
                        .right-con{
                            .collect-btn{
                                text-align: right;
                                .btn{
                                    width: 80px;
                                    height: 30px;
                                    padding: 0;
                                    background: #dd6161;
                                    border-radius: 20px;
                                    text-align: center;
                                    line-height: 30px;
                                    color: #fff;
                                }
                                p{
                                    color: rgb(153, 153, 153);
                                    font-size: 14px;
                                    margin-top: 8px;
                                }
                            }
                        }
                    }
                    .info{
                        font-size: 14px;
                        line-height: 20.5px;
                        height: 62px;
                        overflow: hidden;
                        margin-bottom: 21px;
                        padding-right: 30px;
                        margin-top: 20px;
                    }
                    .botton-con{
                        img{
                            width: 16px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }

    .tag-con{
        position: relative;
        margin-top: 20px;
        display: flex;
        align-items: center;
        background-color: #F6F6F6;
        padding: 10px 20px;
    }
    .tag-con .clear{
        cursor: pointer;
        font-size: 14px;
        color: #999999;
        position: absolute;
        right: 20px;
    }
    .title{
        font-weight: bold;
        font-size: 14px;
        color: #020202;
        margin-right: 10px;
    }
    .title1{
        font-weight: bold;
        font-size: 14px;
        margin-right: 10px;
        cursor: pointer;
    }
    .class-list{
        display: flex;
        align-items: center;
        position: relative;
        border-top: 1px solid rgb(238, 238, 238);
        padding: 10px 20px;
    }
    .class-list .class-con{
        /* display: flex;
        align-items: center; */
        width: 15%;
        color: #020202;
        font-size: 14px;
        line-height: 28px;
        padding-right: 10px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .class-list .class-con:hover{
        color: #D4282D;
    }
    .el-icon-plus{
        position: absolute;
        right: 20px;
    }
    .input-con{
        display: flex;
        align-items: center;
    }
    .el-input{
        
        font-size: 10px;
    }
    ::v-deep .el-input__inner{
        width: 60px;
        height: 28px;
        margin: 0 5px;
        border-radius: 0;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
    }
    ::v-deep .el-checkbox:hover .el-checkbox__label{
        color: #D4282D!important;
    }
    .surebtn{
        background-color: #020202!important;
        color: #ffffff!important;
    }
    .input-con{
        position: absolute;
        right: 20px;
    }
    .default{
        width: 100%;
        margin-top: 20px;
    }
    .default .default-con{
        display: flex;
        align-items: center;
        width: 1200px;
        margin: 0 auto;
        padding: 15px 20px;
        background-color: #F6F6F6;
        box-sizing: border-box;
    }
    .default-con .price{
        display: flex;
        align-items: center;
    }
    .arrow-con{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .pageation{
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
    }
    .page-con{
        width: 286px;
        margin-right: 16px;
        margin-bottom: 20px;
    }
    .page-con:hover{
        box-shadow:0px 1px 8px 0px rgba(0, 0, 0, 0.2);
        background-color: #eeeeee;
    }
    .page-con:nth-child(4n){
        margin-right: 0px;
    }
    .page-con img{
        width: 286px;
        height: 286px;
    }
    .comidty-title{
        color: #020202;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 auto;
    }
    .price-title{
        display: block;
        font-size: 14px;
        line-height: 12px;
        color: #D4282D;
        margin-top: 6px;
        text-align: center;
    }
</style>